<template>
  <Page class="page-my-profile page-edit-my-profile">
    <template #title>Modifica dati profilo</template>

    <template #title-extra>
      <router-link class="btn btn-outline-light" :to="{ name: 'profile' }">Torna indietro</router-link>
    </template>

    <template #default>
      <UserForm v-if="user" :user="user" :feedback.sync="feedback" @submitForm="updateUser" edit />
    </template>
  </Page>
</template>

<script>

import $api from '@/libs/Api/services/api.js';
import rolesMixin from '@/mixins/roles.js';

import Page from '@/views/components/Private/Page.vue';
import UserForm from '@/views/components/Form/UserForm.vue';

export default {
  mixins: [rolesMixin],
  components: {
    Page,
    UserForm,
  },
  data: () => ({
    user: null,
    feedback: null,
  }),
  methods: {
    success () {
      this.feedback = true;
      this.$router.replace({ name: 'profile' });
      this.$oauth2.getMe(true);
    },
    failed (err) {
      this.feedback = false;
      this.$log.error(err);
    },
    updateUser (userForm) {
      const user = { ...userForm };

      this.$api.updateMyProfile(user)
        .then(this.success)
        .catch(this.failed)
      ;
    },
  },
  async beforeRouteEnter (to, from, next) {
    try {
      const res = await Promise.all([
        $api.getMyProfile(),
      ]);
      return next(vm => {
        vm.user = res[0]?.data || {};
      });
    } catch (error) {
      return next(vm => vm.$log.error(error));
    }
  },
};

</script>
